<template>
	<v-dialog
			v-model="dialog"
			transition="dialog-bottom-transition"
			width="1400"
			heigth="600"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
					class="mb-2"
					@click="dialog=true"
					color="primary"
					dark
					v-bind="attrs"
					v-on="on"
			>
				<v-icon left>{{ mdiCreation }}</v-icon> Ссылки
			</v-btn>
		</template>
		<v-container class="grey lighten-5">
      <v-card>
        <v-card-text>
          <v-row v-for="(link, i) in links" :key="i" align="center" justify="center" dense>
            <v-col cols="4">
              <v-text-field v-model="link.link" outlined hide-details label="Ссылка" @change="link.link=cleanLink(link.link, i); checkLinks()"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="link.title" outlined hide-details label="Описание"></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="link.partner_id"
                label="Партнёрка"
                placeholder="Партнёрка"
                outlined
                hide-details
                :items="link.partners"
                :item-text="'title'"
                :item-value="'id'"
                @change="checkPartners"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="link.shop"
                label="Магазин"
                placeholder="Магазин"
                outlined
                hide-details
                :items="shops"
                :item-text="'name'"
                :item-value="'name'"
                return-object
              >
              </v-select>
            </v-col>
            <v-col>
              <v-radio-group v-model="link.type">
                <v-radio label="Основная" value="primary"></v-radio>
                <v-radio label="Дополнительная" value="secondary"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-btn color="error" @click="deleteLink(i)">
                <v-icon>{{mdiDelete}}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-btn color="primary" @click="addLink()">Добавить ещё </v-btn>
        </v-card-text>
      </v-card>
		</v-container>
	</v-dialog>
</template>

<script>
import {mdiDelete, mdiCreation} from '@mdi/js'
import _ from 'lodash'

export default {
  name: 'Links',
  props:{
    links:{
      type: Array,
    },
    errors: {type: Array},
    partners: Array,
    forceUpdate: {type: Number, default: 0}
  },
  data(){
    return{
      mdiDelete, mdiCreation,
      dialog: false,
    }
  },
  updated(){
    this.checkLinks()
    this.checkPartners()
  },
  mounted() {
    if (this.links.length < 1){
      this.addLink()
    }
  },
  watch:{
    forceUpdate(){
      this.cureLinks()
      this.checkLinks()
      this.checkPartners()
    },
    dialog(v){ //есть нюансик: в Editproduct при выборе продукта мы пробрасываем сюда ссылки, но к ним ещё нужно добавить несколько параметров (partners, shop например)
      if (v){
        this.cureLinks()
      }
    },
    links:{
      deep: true,
      handler(){
        this.checkPartners()
      }
    }
  },
  computed:{
    shops(){
      return this.$store.state.shops
    },
  },
  methods:{
    cureLinks(){ //в links[i] = link не сразу есть нужные параметры. создадим их если их не было
      _.each(this.links, (link, i) => {
        if (!_.has(link, 'partners')) {
          this.$set(this.links[i], 'partners', JSON.parse(JSON.stringify(this.partners))) //еботня чтобы vue создал нормальный свой partners а не ссылку на partners из родительского компонента
        }
        if (!_.has(link, 'partners')) {
          this.$set(this.links[i], 'shop', {})
        }
        if (!_.has(link, 'partner_id')) {
          this.$set(this.links[i], 'partner_id', 0)
        }
        if (!_.has(link, 'offer_id')) {
          this.$set(this.links[i], 'offer_id', 0)
        }
        this.cleanLink(link.link, i)
      })
    },
    checkLinks(){
      const postErrors = this.errors.filter(x=>x.err !== 'noLinks') // сначала на всякий случай удалим предыдущие сообщения об ошибках, связанных со ссылками
      let hasLink = true
      if (this.links.length === 0){
        hasLink = false
      }else{
        _.each(this.links, (link, i)=>{
          this.cleanLink(link.link, i)
          if (link.link === '' || link.partner_id === 0 || link.shop_id === 0 || link.partner_id === '' || _.isEmpty(link.shop)){
            postErrors.push({err: 'noLinks', text: 'Проблемы со ссылкой'})
            hasLink = false
          }
        })
      }
      if (hasLink){
        const errors = this.errors.filter(x=>x.err !== 'noLinks')
        this.$emit('update:errors', errors)
      }else{
        this.$emit('update:errors', postErrors)
      }
    },
    addLink(){
      const partners = JSON.parse(JSON.stringify(this.partners))
      this.links.push({link: '', title: '', type: 'primary', index: this.links.length, partners: partners, shop: {}, partner_id: '', offer_id:0})
      this.$emit('update:links', this.links)
    },
    deleteLink(i){
      this.links.splice(i, 1)
      this.$emit('update:links', this.links)
    },
    cleanLink(link, i){
      const arr = link.split('?')
      this.checkLink(arr[0], i)
      //this.product.shop.admitad_id
      return arr[0]
    },
    checkLink(link, i){
      try {
        const url = new URL(link)
        if (url.host === 'aliexpress.ru'){
          this.links[i].shop = this.shops.find(x=>x.id===2)
          // this.links[i].shop = this.shops.find(x=>x.id===1)
        }
        else if (url.host === 'www.citilink.ru'){
          this.links[i].shop = this.shops.find(x=>x.id===3)
        }
        else if (url.host === 'www.eldorado.ru'){
          this.links[i].shop = this.shops.find(x=>x.id===4)
        }
        else if (url.host === 'market.yandex.ru'){
          this.links[i].shop = this.shops.find(x=>x.id===5)
        }
        else if (url.host === 'www.mvideo.ru'){
          this.links[i].shop = this.shops.find(x=>x.id===6)
        }
        else if (url.host === 'shop.mts.ru'){
          this.links[i].shop = this.shops.find(x=>x.id===7)
        }
        else if (url.host === 'www.ozon.ru'){
          this.links[i].shop = this.shops.find(x=>x.id===8)
        }else if (url.host === 'sbermegamarket.ru'){
          this.links[i].shop = this.shops.find(x=>x.id===10)
        }
        this.checkShop(i)
      } catch (error) {
      }
    },
    checkShop(i) {
      const v = this.links[i].shop //тупо скопировал это из editproduct и переназначил v
      _.each(this.links[i].partners, x => {
        x.disabled = false
      }) //сначала всех включим, и отключим всё что неьзя
      if (_.has(v, 'id')) {
        if (v.id === 1 || v.id === 2) { //ali
          this.links[i].partners.find(x => x.id === 2).disabled = true
          this.links[i].partners.find(x => x.id === 3).disabled = true
          this.links[i].partner_id = 1
        } else if (v.id === 3 || v.id === 4 || v.id === 5) { //ситилинк эльдорадо яндекс маркет
          this.links[i].partners.find(x => x.id === 1).disabled = true
          // this.links[i].partners.find(x => x.id === 3).disabled = true
          this.links[i].partner_id = 3
        } else if (v.id === 6) { //мвидео
          this.links[i].partners.find(x => x.id === 1).disabled = true
          this.links[i].partners.find(x => x.id === 2).disabled = true
          this.links[i].partner_id = 3
        } else if (v.id === 7) { //мтс
          this.links[i].partners.find(x => x.id === 1).disabled = true
          this.links[i].partner_id = 3
        } else if (v.id === 8) { //8 - озон
          this.links[i].partners.find(x => x.id === 1).disabled = true
          this.links[i].partners.find(x => x.id === 2).disabled = true
          this.links[i].partners.find(x => x.id === 3).disabled = true
          this.links[i].partner_id = 4
        } else if (v.id === 10) { //10 - сбермегамаркет
          this.links[i].partners.find(x => x.id === 1).disabled = true
          this.links[i].partners.find(x => x.id === 2).disabled = false
          this.links[i].partners.find(x => x.id === 3).disabled = false
          this.links[i].partner_id = 3
        } else if (v.admitad_id > 0){
          this.links[i].partners.find(x => x.id === 1).disabled = true
          this.links[i].partners.find(x => x.id === 2).disabled = true
          this.links[i].partners.find(x => x.id === 3).disabled = false
          this.links[i].partner_id = 3
        }
      }
    },
    checkPartners(){
      _.each(this.links, (link, i)=>{
        if (_.has(link, 'shop')){
          if(link.partner_id===2){
            this.links[i].offer_id = link.shop.epn_id
          }else if (link.partner_id===3){
            this.links[i].offer_id = link.shop.admitad_id
          }else{
            this.links[i].offer_id = 0
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
